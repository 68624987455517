@import "../../../../custom/variables";

.page {
  &__company-list {
    .company-tabs {
      padding: 20px 0;
    }

    .company-list {
      max-width: 1690px;
      margin: 0 auto;
      padding: 0 20px;
    }

    .title {
      padding: 40px 0 12px 0;
    }

    .company-filter {
      padding: 5px 0 30px;
      display: flex;
      align-items: baseline;

      .list-filter {
        flex-grow: 1;
        color: #008E9A;
        display: flex;
        justify-content: flex-end;
        font-size: 16px;

        &__pager-size {

        }
      }
    }

    .completeness {
      vertical-align: middle!important;
    }

    .status-branch-col {
      width: 220px;
    }

    .actuality-col {
      width: 125px;
    }

    .status-col {
      width: 60px;
    }

    .platform-col {
      width: 105px;
    }

    .progress-col {
      //width: 150px;
    }

    .branch {
      div {
        padding-left: 30px;

        background: url("../../../../images/branch_ico.png") 0 5px no-repeat;
        background-size: 25px 12px;

      }
    }
    .check {
    }

    ul {
      padding: 0;
      margin: 0;

      &.align-center {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: max-content;
        margin: 0 auto;
      }

      li {
        display: flex;
        list-style: none;
        align-items: center;
      }
    }

    .progress {
      padding-top: 2px;
      padding-bottom: 12px;

      ul.scale {
        display: flex;

        li {
          &.red{
            background-color: #EB5757;
          }
          &.green{
            background-color: #6FCF97;
          }

          display: flex;
          list-style: none;
          align-items: center;

          margin-right: 3px;
          width: 4px;
          height: 12px;
          border-radius: 2px;
          background-color: #D9D9D9;

        }
      }
    }

    .actuality-block {
      ul {
        li {
          display: flex;
          height: 24px;
        }
      }

      .actuality {
        display: flex;
        margin: 2px 0;
        justify-content: flex-start;

        span:first-child {
          width: 18px;
          height: 18px;
          display: block;
        }

        span.count {
          font-size: 12px;
          margin-left: 5px;
          color: #EB5757;
          line-height: 18px;
        }

        &__pause {
          background: transparent url("../../../../images/icons/pause.svg") 0 0 no-repeat;
        }

        &__error {
          background: transparent url("../../../../images/icons/error.svg") 0 0 no-repeat;
        }

        &__check {
          background: transparent url("../../../../images/icons/check.svg") 0 0 no-repeat;
        }

        &__stop {
          background: transparent url("../../../../images/icons/stop.svg") 0 0 no-repeat;
        }

      }

    }



    .platform-block {

      ul {
        li {
          display: flex;
          height: 24px;
        }
      }

      .rating {
        display: flex;
        margin-left: 25px;
        align-items: flex-start;
        position: relative;
        top:-2px;

        .pi-star {
          color: #ffc547;
          font-size: 18px;
          line-height: 24px;
          margin-right: 5px;
        }

        .value {
          display: flex;
          align-items: center;
          width: 25px;
          font-size: 12px;
          position: relative;
          top:4px;
        }
      }
    }
  }


  .p-datatable {
    color: #2b323a;
    line-height: 19.2px;
  }
  .company-table {
    .p-datatable-tbody {
      .p-datatable-row {
        cursor: pointer!important;
      }
    }
  }
  .company-table .p-datatable-tbody .p-datatable-row:hover {

    background-color: #e9ecef;
  }
  .company-table {
    padding-bottom: 50px;

    &__header {
      padding: 5px 30px 20px 0;
    }
  }

  .table {
    &__wrapper {
      .loader {
        position: absolute;
        left: 0;
        top: 0;
        background-color: #FFF;
        opacity: 0.5;
        z-index: 2;
        width: 100%;
        height: 100%;
      }
    }
  }

  thead {
    tr {
      th {
        padding-left: 0!important;
      }

    }
  }

  tbody {
    tr {
       td {
        border-bottom: 1px solid #DBDBDB;
        padding: 8px 5px;
        vertical-align: top;
        white-space: normal!important;

      }

      &.align-top {
        td {
          vertical-align: top;
        }
      }

      &.first-row {
        td {
          border-bottom: 1px solid #373947 !important;
        }
      }

      &:hover {
        background-color: transparent;
      }
    }
  }
}

@media (max-width: 1600px) {
  .page {
    &__company-list {
      .company-list {
        max-width: 1240px;
        margin: 0 auto;
        padding: 0 20px;
      }

      .title {
        max-width: 1240px;
        padding: 40px 0 0 0;
      }
    }
  }
}
