@import "ant";
$font-family: 'Stolzl Book', 'Stolzl', Arial, Helvetica, sans-serif;
$font-family2: 'Stolzl', Arial, Helvetica, sans-serif;
$font-size: 14px;
$font-weight: 400;

$border-radius: 2px;
$color-text: rgba(48, 48, 48, 0.7);
$primary-color: #2B323A;
$border-color: #A6A6A6;

* {
  box-sizing: border-box;
}

html, body, #root {
  height: 100%;
}

iframe {
  display: none;
}

.telegram__wrapper {
  iframe {
    display: block;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-family: $font-family !important;
  font-size: $font-size !important;
  font-weight: $font-weight !important;
  color: $color-text !important;
  height: 100%;

}
.page-wrapper {
  display: grid;
  grid-template-rows: 65px 1fr 108px;
  //grid-template-columns: minmax(150px, 1fr) repeat(var(--my-repeat), minmax(80px, 100px));
  //grid-auto-columns: min-content;

  //65px
  //1fr
  //105
}

.content {
  height: 100%;
  //flex: 1 0 auto;
}

.page {
  h1 {
    margin: 0;
    padding: 0;
    font-size: 32px;
    color: #303030;
  }

  h3 {
    margin: 0;
    padding: 0;
    font-size: 24px;
    color: #303030;
  }

  h4 {
    color: #373947;
    font-size: 20px;
    margin: 0;
    padding: 0;
  }
}

.page-menu {
  display: none !important;
}
.p-datatable {
  tr {
    cursor: default!important;
  }
}
.OKReviewCalendar {
  font-family: $font-family;

  .react-datepicker {
    &__month-text {
      padding: 5px 10px;

      &:hover {
        border-radius: 0;
        background-color: #01B3C2;
        color: #fff;
      }
    }

    &__month {
    }

    &__month--selected {
      border-radius: 0;
      background-color: #008E9A;
      color: #fff;
    }
  }
}

.inputTextError {
  color: #C55469;
  font-size: 10px;
  position: absolute;
  right: 0;
  top: -20px;
}

.input-switch {
  display: flex;
  align-items: center;

  .p-inputswitch {
    height: 16px;
    width: 33px;

    &:hover {
      .p-inputswitch-slider {
        background-color: #373947 !important;
      }

    }

  }

  .p-inputswitch-checked {
    &:hover {
      .p-inputswitch-slider {
        background-color: #32CC32 !important;
      }

    }

    .p-inputswitch-slider {
      background-color: #32CC32;

      &:before {
        transform: translateX(17px);
      }
    }
  }

  .p-inputswitch-slider {
    border-radius: 15px;
    background-color: #373947;

    &:before {
      left: 3px;
      margin-top: -5px;
      height: 10px;
      width: 10px;
      border-radius: 15px;

    }
  }
}

.p-button  {
  padding: 0.4rem 1.5rem;

  .p-button-label {
    font-family: $font-family;
    font-size: 14px;
  }

  &.p-button-outlined {
    background: #FFFFFF;
    border: 1px solid #008E9A;
    color: #008E9A !important;
    box-sizing: border-box;
    border-radius: 2px !important;

    &:hover {
      background: #008E9A;
      color: #fff !important;
      border: 1px solid #008E9A !important;
    }
  }

  &.p-button-danger {
    &.no-hover {
      &:hover {
        background: #EB5757 !important;
        color: #fff !important;
        border: 1px solid #EB5757 !important;
      }
    }
    &.p-button-outlined {
      background: #FFFFFF;
      border: 1px solid #EB5757;
      color: #EB5757 !important;

      &:hover {
        background: #EB5757 !important;
        color: #fff !important;
        border: 1px solid #EB5757 !important;
      }
    }

    box-sizing: border-box;
    border-radius: 2px !important;

    &:hover {
      background: #FFFFFF!important;
      border: 1px solid #EB5757!important;
      color: #EB5757 !important;

    }
  }
}

.chain_ico {
  position: relative;
  top:3px;
  margin-left: 2px;
  background: transparent url("../images/chain_ico.svg") 0 0 no-repeat;
  background-size: 12px 12px;
  width: 12px;
  height: 12px;
  display: block;
}

.text-color {
  &--gray {
    color: #BDBDBD;
  }
}

[aria-labelledby^="id-"][aria-labelledby*="-title"] {
  display: none;
}