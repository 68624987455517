.status {
  width: auto;
  padding: 4px 11px;
  display: inline-grid;
  margin: 2px;

  &:last-child {
    margin-bottom: 0;
  }

  &.type-active {
    background: #E7F8EE;
    border-radius: 1px;
    color: #38A749;
  }

  &.type-temp_not_working  {
    background: #FFF7E7;
    border-radius: 1px;
    color: #FF8A00;
  }

  &.type3 {
    background: #D4F2F4;
    border-radius: 1px;
    color: #008E9A;
  }

  &.type-no_longer_works {
    background: #FFECEC;
    border-radius: 1px;
    color: #EB5757;
  }

  &.type-own {
    background: #D4F2F4;
    border-radius: 1px;
    color: #008E9A;
  }

  &.type-new {
    background: #E7F8EE;
    border-radius: 1px;
    color: #38A749;
  }

  &.type-franchise {
    background: #E7F8EE;
    border-radius: 1px;
    color: #38A749;
  }

  &.type5 {
    background: #f0f0f0;
    border-radius: 1px;
    color: #666;
  }

}