.side-bar {
  overflow-y: scroll;
}
//padding: 40px 0 12px 0;
.company-statistics {
  .statistics {
    &__wrapper {
      padding-top: 10px;

      .block {
        &__title {
          h3 {
          }
        }
      }

      .place-statistic {
        margin-top: 40px;
        margin-bottom: 40px;
        border: 1px solid #C1C0C0;
        border-radius: 8px;

        .item {
          border-width: 0;
          border-radius: 0;
        }

        &__header {
          padding: 8px 10px 0 40px;
          border-bottom: 1px solid #C1C0C0;

          ul {
            display: flex;

            li {
              list-style: none;
              cursor: pointer;
              padding: 10px 20px;
              display: flex;

              img {
                display: block;
                margin-right: 5px;
              }

              &.active {
                border-bottom: 2px solid #008E9A;
              }
            }
          }
        }

        &__content {
          .content__block {
            display: none;


            &.active {
              display: block;
            }
          }
          .content-top {
            display: flex;
            gap: 40px;
            padding: 8px 40px 0 40px;

            .item {
              h4 {
                font-size: 16px;
                font-weight: 400;
                margin: 0 0 15px;
              }

              font-weight: 600;
              padding: 40px 0;

              div {
                font-size: 20px;
                color: #373947;
                align-self: center;
                display: flex;

                img {
                  display: block;
                  margin-right: 10px;
                }
              }

            }
          }

          .content-bottom {
            border-top: 1px solid #C1C0C0;
            display: flex;
            flex-direction: column;
            padding: 25px 40px 40px 40px;

            .title {
              margin-bottom: 10px;
              font-size: 16px;
              color: #EB5757;
              font-weight: 600;
              padding: 0;
            }

            .items-container {
              display: flex;
              gap: 30px;
              max-width: 720px;
              flex-wrap: wrap;
              margin-bottom: 20px;
            }

            .item {
              font-size: 14px;
              width: 150px;

              &.no-errors {
                width: auto;
                img {
                  position: relative;
                  left: 3px;
                  top:5px;
                }
              }

              h5 {
                font-size: 12px;
                line-height: 20px;
                margin: 0 0 10px;
              }

              span {
                font-size: 14px;
                color: #373947;
                font-weight: 600;

                &.error {
                  padding: 0;
                  text-align: left;
                  color: #EB5757;
                  cursor: pointer;

                  span {
                    color: #EB5757;
                  }
                }
              }


            }
          }
        }
      }

    }
  }
}

