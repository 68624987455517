.qrcode-table {

  .table {
    //min-width: 1000px;
    font-size: 14px;
    max-width: max-content;
    color: #2B323A;

    .ad {
      &__ico {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        img {
          display: block;
          margin-bottom: 3px;
        }
      }
    }

    &__header {
      font-size: 14px;
      text-align: center;
      line-height: 16px;
      .table__cell {
        font-weight: 600;
      }
    }

    &__body {
      border: 1px solid #ccc;
      border-radius: 8px;
    }

    .row {
      border: 1px solid #ccc;
      border-radius: 8px;
      margin-bottom: 20px;
    }

    &__row {
      display: grid;
      //grid-template-columns: 5fr repeat(6, 1fr) minmax(70px, 1fr) 1fr 1fr minmax(100px, 1fr) 1fr 1fr minmax(100px, 1fr);
      grid-template-columns: minmax(150px, 1fr) repeat(var(--my-repeat), minmax(80px, 120px));
      grid-auto-columns: min-content;
      border-bottom: 1px solid #ccc;
      min-height: 40px;

      &:last-child {
        border-bottom-width: 0;
      }
    }

    &__cell {
      padding: 8px;
      text-align: center;
      align-self: center;
      overflow: hidden;

      .diff {
        &:not(.diff__main) {
          position: relative;
          top: 3px;
        }

        &.top0 {
          top: 0;
        }

        &__up {
          color: #19c78a;
        }

        &__down {
          color: #EB5757;
        }

        &__gray {
          color: #BDBDBD;
        }

      }

      span {
        display: block;
        line-height: 14px;
        font-size: 14px;
      }

      &:first-of-type {
        text-align: left;
      }

    }

    .cell {
      &__title {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        white-space: nowrap;
        line-height: 20px;
        div {

          &.ico {
            position: relative;
            top: -2px;
            margin-right: 6px;
            //margin-left: 20px;
          }
        }
        .category {
          font-size: 12px;
        }
      }
    }
  }
}


.company-data-compare-table {

  .table {
    &__header {
      font-size: 12px;
      line-height: 12px;
      font-weight: bold;
    }
    &__body {
      font-size: 12px;
      line-height: 12px;

      .table__cell {
        align-self: flex-start;
      }

      ul {
        padding: 0;
        margin: 0;

        li {
          list-style: none;
          margin-bottom: 5px;
        }
      }
    }

    &__row {
      display: grid;
      //grid-template-columns: repeat(var(--my-repeat), 1fr);
      grid-template-columns:  repeat(8, 1fr);
      grid-auto-columns: auto;
      border-bottom: 1px solid #ccc;
      min-height: auto;

      &:last-child {
        border-bottom-width: 0;
      }
    }

    &__cell {
      text-align: left;

      &:first-of-type {
        display: flex;
        align-items: flex-start;

        img, .okreview-ico {
          margin-right: 5px;
          position: relative;
          top: -2px

        }
      }

      img {
        display: block;
      }

      .phone-list {
        &__icon-wrapper {
          display: block;
          padding-right: 3px;
        }

        li {
          display: flex;
          white-space: nowrap;
          svg {
            min-width: 14px;
          }
        }

        span {
          font-size: 12px;
          line-height: 12px;

          &.color {
            &__green {
              color: #19c78a;
            }

            &__red {
              color: #EB5757;
            }
          }

        }

      }
    }
  }

}

.compare-side-bar {

  .p-sidebar-icons {
    position: absolute;
    right: 75px;
    z-index: 1;
  }

  &__wrapper {

  }

  &__header {
    padding-top: 5px;
    padding-bottom: 20px;
    margin-bottom: 5px;
    border-bottom: 2px solid #008E9A;

  }

  &__title {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 8px;
    width: 50%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__subtitle {
    display: flex;
  }

  &__subtitle-text {
    font-size: 12px;
    background-color: #EB57574D;
    color: #EB5757;
    border-radius: 3px;
    display: flex;
    padding: 0 9px;
    margin-right: 20px;
  }

  &__subtitle-date {
    color: #7A7A7A;
    font-size: 12px;
    display: flex;
    align-items: center;

    img {
      margin-right: 5px;
    }
  }
}