@import 'src/fonts/stylesheet';
@import "src/custom/variables";
@import "src/components/App";
body input,
body .p-component,
body .p-link,
body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input,
body .p-chips > ul.p-inputtext .p-chips-input-token input,
body .p-datatable .p-editable-column input,
body .p-treetable .p-editable-column input,
body .p-inputtext,
body .p-terminal .p-terminal-input {
  font-family: $font-family !important;
  font-size: $font-size !important;
  font-weight: $font-weight !important;
  line-height: 1.7rem;
}

a {
  text-decoration: none;
}

.grecaptcha-badge {
  visibility: hidden;
}

textarea {
  resize: none;
}

body .p-button {
  background-color: #008E9A;
  border: 1px solid #008E9A;
  font-size: 14px;
  //padding: 8px 29px;
  border-radius: 2px;

  &.p-button-danger {
    &:enabled {
      &:focus {
        box-shadow: none;
        -webkid-box-shadow: none;
      }
    }
  }

  &:focus {
    box-shadow: none;
    -webkid-box-shadow: none;
  }

  &.btn-outlined {
    border: 1px solid #fff !important;

    &:hover {
      background-color: #fff !important;
      color: #008E9A !important;
    }
  }
}

input::placeholder {
  color: #888888;
}

input{
  color: #303030 !important;
}

.p-inputtext {
  padding: 6px 8px !important;
  color: #303030 !important;
  height: 40px !important;
  border-radius: $border-radius !important;
}

.p-inputtext:enabled {
  border: 0.5px solid #343546 !important;
  padding: 6px;
  color: #303030;
}

.p-inputtext:enabled:focus {
  box-shadow: none !important;
  border: 0.5px solid #343546 !important;
}

.p-disabled, .p-component:disabled{
  opacity: 0.3!important;
}

body .p-button:hover {
  background-color: #01B3C2 !important;
  border: 1px solid #01B3C2 !important;
}

.text-field {
  margin: 12px 0;
  .input-search {
    border-radius: 30px!important;
  }

}
.date-field {
  margin: 12px 0;
}

.pi-chevron-down:before {
  color: #008E9A;
}

.p-inputwrapper {
  width: 175px;
}

.ant-select-selection-placeholder {
  font-size: 14px;
  color: #888888 !important;
  font-family: $font-family;
  font-weight: normal;
  left: 8px !important;
  padding: 0 !important;
  top: 0;
  line-height: 40px !important;
}

.p-multiselect .p-multiselect-label.p-placeholder {
  color: #888888 !important;
  line-height: 1.7rem;
  height: 42px;

}

.p-multiselect .p-multiselect-label {
  padding: 6px 8px !important;
}

.p-inputwrapper.p-error, .p-inputwrapper.p-invalid {
  border-color: #dc3545 !important;
}

.p-calendar.p-error > .p-inputtext, .p-calendar.p-invalid > .p-inputtext {
  border-color: #dc3545 !important;
}

.p-inputwrapper-focus {
  box-shadow: none !important;
}

.p-scrollpanel-bar {
  opacity: 1;
}

.p-dropdown, .p-multiselect {
  height: 42px;
  border: 0.5px solid #343546 !important;
  border-radius: $border-radius !important;
}

.p-float-label > label {
  color: #888888!important;
  left: 0.50em !important;
  margin-top: -.4rem;

  &.p-invalid, span {
    color: #dc3545;
  }
}

.p-inputtext.p-error, .p-inputtext.p-invalid {
  border-color: #dc3545 !important;
}

.p-float-label {
  .ant-select-auto-complete {
    /*
    .ant-select-selector {
      .ant-select-selection-search {}
    }
    */
  }
}

.p-float-label > input:focus ~ label {
  -webkit-transition-duration: 0.15s;
  transition-duration: 0.15s;
  font-size: 12px;
  top: -10px;
  left: 0 !important;
  color: #90AFC4 !important;
}

.p-float-label > .p-inputwrapper-filled ~ label,
.p-float-label > .p-inputwrapper-focus ~ label,
.p-float-label > input.p-filled ~ label,
.p-float-label > input:focus ~ label,
.p-float-label > input:focus ~ label,
.p-float-label > input.p-filled ~ label,
.p-float-label > .p-inputwrapper-focus ~ label,
.p-float-label > .p-inputwrapper-filled ~ label {
  font-size: 12px;
  top: -10px;
  left: 0 !important;
  color: #90AFC4 !important;
}

body .p-checkbox .p-checkbox-box .p-checkbox-icon {
  font-size: 12px;
  top: 2px;
}

body .p-checkbox .p-checkbox-box.p-highlight {
  background-color: #008E9A;
  border: 1.5px solid #008E9A;
  box-sizing: border-box;
  border-radius: 2px;
}

body .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  border-color: #01B3C2;
  background-color: #01B3C2;
  color: #ffffff;
}

body .p-checkbox .p-checkbox-box:not(.p-disabled).p-focus {
  box-shadow: none !important;
  border: 0.5px solid #01B3C2 !important;
}

.p-buttonset .p-button {
  border-color: #a6a6a6 !important;
}

.p-buttonset .p-button:first-of-type {
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
}

.p-buttonset .p-button:last-of-type {
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

.data-text {
  &__wrapper {
    white-space: nowrap;

    .p-button {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    .p-input-icon-right {
      .pi {
        color: #008E9A;
      }
    }
  }
}

//ant-select-selection-search-input

.p-float-label > input:focus ~ label > span,
.p-float-label > input.p-filled ~ label > span,
.p-float-label > .p-inputwrapper-focus ~ label > span,
.p-float-label > .p-inputwrapper-filled ~ label > span {
  display: none;
}

.loading2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.form-loading {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  z-index: 2;
}

/*
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  min-height: 460px;
  .page {
    visibility: hidden;
  }
}
*/

.loading-dash {
  min-height: auto;
}

.p-dialog-titlebar {
  display: none;
}

.p-dialog-content {
  margin: 0 !important;
  padding: 0 !important;
}

.p-progress-spinner-circle {
  stroke: #008E9A !important;
}

.p-datepicker-calendar {
  .p-highlight {
    background-color: #008E9A !important;
  }
}

.text-align {
  &-right {
    text-align: right !important;
  }

  &-left {
    text-align: left !important;
  }

  &-center {
    text-align: center !important;
  }

}

.pt {
  &-0 {
    padding-top: 0 !important;
  }
}
