label {
  &.active {
    font-size: 12px;
    top: -10px!important;
    left: 0 !important;
    color: #90AFC4 !important;
  }
}
.p-float-label {
  span.label {
    position: absolute;
    display: block;
    left: 0;
    margin-top: -6.4px;
    padding-bottom: 3px;
    top: -10px;
    font-size: 12px;
    line-height: 12px;
    color: #90AFC4;
  }
}

.inputTextError {
  color: #c55469;
  font-size: 10px;
  position: absolute;
  right: 0;
  top: -20px;
}