.review-filter {
  padding: 12px 0 28px 0;
}

.filter {

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    color: #303030;
    margin-right: 16px;

    h3 {
      position: relative;
      top: 5px;
    }
  }


  &__wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    margin-top: 10px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    &:last-child {
      margin-right: 0;
    }
    .tooltip {
      &__wrapper {
        margin-left: 6px;
      }
    }

  }
}




