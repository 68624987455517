.page {
  &__analytics {
    max-width: 1690px;
    min-height: 32px;
    padding: 0 20px;
    margin: 0 auto !important;
    font-size: 16px;

    td, th {
      min-height: 45px;
      height: 45px;
    }

    .title {
      padding: 40px 0 12px 0;
      position: relative;

    }

    .p-button {
      &.excel {
        position: absolute;
        right: 0;
        bottom: 2px;
        background: transparent url("./../../../images/excel_ico.svg") 13px 50% no-repeat!important;
        padding-left: 48px;

        &:hover {
          background: #01B3C2 url("./../../../images/excel_ico2.svg") 13px 50% no-repeat!important;
        }
      }
    }

    .analytics-tabs {
      padding: 20px 0;
      a {
        white-space: nowrap!important;
      }
    }

    .analytics-filter {
      padding: 5px 0 30px;
      display: flex;
      align-items: flex-end;
    }

    .application-table, .analytics-table {
      padding-bottom: 50px;

      &__header {
        padding: 5px 30px 20px 0;
      }
    }

    .application-table {
      table {
        tr {
          th:first-child {
            width: 250px;
          }
        }
      }
    }

    .table {
      &__wrapper {

        .loader {
          position: absolute;
          left: 0;
          top: 0;
          background-color: #FFF;
          opacity: 0.5;
          z-index: 2;
          width: 100%;
          height: 100%;
        }

      }
    }

    .branch {
      .p-datatable {
        width: 900px;
      }
    }

    .p-datatable {
      //width: 1250px!important;
      color: #2B323A;

      /*
      table {
        table-layout: auto;
      }
      */

      &.wide {
        width: 100%;
      }

      .p-datatable-thead {
        tr {
          th {
            cursor: default;
            padding: 8px 5px;
          }

          .source {
            position: static;
            width: 135px;
            cursor: pointer;
          }

          .source-img {
            width: 80px;
          }

          .branch {
            width: 350px;
          }

          .negative {
            width: 120px;
          }

          .rating {
            width: 150px;
          }

          .scores {
            width: 70px;

            & + th {
              width: 80px;
            }
          }

          .review {
            width: 70px;

            & + th {
              width: 50px;
            }

          }

          .time, .answer {
            width: 120px;

            &__tooltip {
              display: flex;
              justify-content: flex-end;
              &.align-right {
                justify-content: flex-start;
              }
              .tooltip {
                margin-left: 5px;

                b {
                  color: #212529;
                }
              }
            }
          }

          .time {
            width: 170px;
          }

        }
      }

      tbody {
        tr {
          cursor: default;

          td {
            border-bottom: 1px solid #DBDBDB;
          }

          &.align-top {
            td {
              vertical-align: top;
            }
          }

          &.first-row {
            td {
              border-bottom: 1px solid #373947 !important;
            }
          }

          &:hover {
            background-color: transparent;
          }
        }
      }

      tr {
        &.align-top {
          td {
            vertical-align: top;
          }
        }

        th, td {
          padding: 8px 0;
          line-height: 1rem;

          .number {
            padding: 0 8px;
          }

          .rating {

            .pi-star {
              color: #FFC547;
              font-size: 14px;
              margin-right: 5px;
            }

            .value {
              width: 25px;
              display: inline-block;
            }
          }

          .diff {
            margin-left: 8px;
            //min-width: 35px;
            display: inline-block;

            &__up {
              color: #6FCF97;
            }

            &__down {
              color: #EB5757;
            }
          }

          .name {
            display: flex;
            line-height: 1.2rem;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 15px;

            div {
              white-space: nowrap;

              &:last-child {
                text-overflow: ellipsis;
                width: 100%;
                overflow: hidden;
              }
            }

            img {
              display: block;
              margin-right: 7px;
            }

            &.colored {
              color: #2F80ED;
            }

            .ico {
              margin-right: 6px;
              margin-top: 1px;
            }
          }

          &:last-child {
            padding-right: 10px;
            width: 50px;
          }

          &.align {
            &__right {
              text-align: right;
            }

            &__center {
              text-align: center;
            }
          }

        }
      }

      .colored {
        color: #008E9A;
      }
    }

  }

  &__analytics-mobile {
    padding: 0 20px;
    line-height: 1.3rem;

    .p-button {
      &.excel {
        background-image: none !important;
        padding: 4px 16px 4px;

        position: absolute;
        right: 0;
        bottom: 2px;

      }
    }

    .title {
      padding: 35px 20px 12px 0;
      position: relative;

      h1 {
        font-size: 32px;
      }

    }

    h2 {
      font-size: 20px;
      color: #373947;
    }


    .analytics-filter {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 20px;
      font-size: 12px;

      .filter {
        &__wrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          width: 100%;
        }

        &__clear-btn {
          padding: 5px 0;
        }

        &__item {
          margin-right: 5px;

          .okCalendar {
            font-size: 12px;

            .react-datepicker {

              &__navigation-icon {
                top: -5px;
              }

              &__current-month {
                padding: 12px 0 10px 0;
                font-size: 14px;
              }

              &__month-container {
                margin-bottom: 10px;
              }
            }

            .react-datepicker__day, .react-datepicker__day-name {
              width: 30px;
              height: 30px;
              line-height: 32px;
            }
          }

          .calendar {
            padding: 3px 10px;

            &__left {
              padding-right: 5px;
            }

            &__right {
              padding-left: 5px;
            }

            &__separator {
              line-height: 26px;
            }
          }

          .filter-slider {
            a {
              padding: 3px 10px;
            }
          }

          .filter-button {
            &__title {
              padding: 3px 10px;
            }
          }
        }
      }
    }

    .analytics-tabs {
      padding: 20px 0 25px;
      margin-bottom: 5px;
      overflow-y: scroll;
      width: 100%;

      a {
        font-size: 24px !important;
        white-space: nowrap!important;
      }

    }

    .analytics-list {
      padding: 0;

      &__table {
        padding-top: 0;
      }

      &__wrapper {

      }

      &__item {
        border: 1px solid #C1C0C0;
        border-radius: 4px;
        padding: 20px;
        margin-bottom: 20px;
        color: #373947;
      }

      .item {
        &__wrapper {
          display: flex;
          flex-direction: column;

          .name {
            display: flex;

            padding-bottom: 10px;
            line-height: 1.2rem;

            .ico {
              margin-right: 6px;
              margin-top: 1px;
            }
          }

          .rating {

            .pi-star {
              color: #FFC547;
              font-size: 14px;
              margin-right: 5px;
            }

            .value {
              width: 25px;
              display: inline-block;
            }
          }

          .row {
            display: flex;
            flex-direction: row;
            padding: 5px 0;
          }

          .col {
            width: 50%;

            &:first-child {
              color: #7A7A7A;
            }

            &:last-child {
              padding-left: 15px;
            }
          }

          .diff {
            &__up {
              color: #6FCF97;
            }

            &__down {
              color: #EB5757;
            }
          }

          .align {
            &__bottom {
              display: flex;
              //align-items: flex-end;
            }

            &__right {
              text-align: right;
            }
          }

        }
      }
    }
  }
}

@media (max-width: 1600px) {

  .page__analytics {
    max-width: 1240px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .qr-table {
    width: auto !important;
  }

}
